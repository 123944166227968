import React, { useEffect, useState } from "react";

import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";

import PasswordHash from "password-hash";
import { Link, Redirect } from "react-router-dom";
import isEmail from "validator/lib/isEmail";

import { withStyles } from "@material-ui/core";
import useStyles from "./styles/index";

import AppHeader from "../../components/AppHeader";
import AppLoading from "../../components/AppLoading";
import SelectJurusan from "../../components/AppSelect/jurusan";
import SelectKampus from "../../components/AppSelect/kampus";
import {
  auth,
  firestore,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";
import { getData } from "../../utils/localforage";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: 3,
    "& .MuiOutlinedInput-root": {
      fontSize: 13.33,
      fontWeight: 500,
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
      "&:hover fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
    },
  },
})(TextField);

function RegisterForm(props) {
  // Firebase Use
  const { user, loading } = useFirebase();

  // Sign in With Google & Facebook
  // const handleSocialLogin = (provider) => () => {
  //   auth.signInWithRedirect(provider);
  // };

  // Set to database for Social Login
  useEffect(() => {
    if (user) {
      const getResult = async () => {
        try {
          const result = await auth.getRedirectResult();
          if (result.credential) {
            const user = result.user;
            const date = new Date();
            const ref = firestore.doc(`siswa/${user.uid}`);
            const snap = await ref.get();
            const utm_source = await getData("utm_source");
            if (snap.exists) {
              await ref.set(
                {
                  ...snap.data(),
                  nama: user.displayName,
                  email: user.email,
                  diperbarui_pada: date,
                },
                { merge: true }
              );
            } else {
              await ref.set(
                {
                  nama: user.displayName,
                  email: user.email,
                  dibuat_pada: date,
                  utm_source,
                },
                { merge: true }
              );
            }
          }
        } catch (error) {
          console.log(error.message);
        }
      };
      getResult();
    }
  }, [user]);

  // Class
  const classes = useStyles();

  // Form
  const [form, setForm] = useState({
    email: "",
    password: "",
    repeat_password: "",
    name: "",
    username: "",
    showPassword: false,
    showPasswordd: false,
    no_hp: "",
    instagram: "",
    kampus: {
      nama: "",
      ptn_id: "",
    },
    jurusan: {
      nama: "",
      jurusan_id: "",
    },
  });

  // Error Form
  const [error, setError] = useState({
    email: "",
    password: "",
    repeat_password: "",
    name: "",
    username: "",
    no_hp: "",
    kampus: "",
    jurusan: "",
  });

  const handleClickShowPassword = () => {
    setForm({ ...form, showPassword: !form.showPassword });
  };

  const handleClickShowPasswordd = () => {
    setForm({ ...form, showPasswordd: !form.showPasswordd });
  };

  // Submit Button
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [loadingBd, setLoadingBd] = useState(false);

  // On Change
  const handleChange = (e) => {
    if (e.target.name === "no_hp") {
      const inputValue = e.target.value;
      const sanitizeInput = inputValue.replace(/[^0-9+]/g, "");
      e.target.value = sanitizeInput;
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  // Validator Error
  const validate = async () => {
    const newError = { ...error };

    if (!form.name) {
      newError.name = "Nama wajib diisi";
    }

    if (!form.username) {
      newError.username = "Username wajib diisi";
    } else {
      const validateUsername = functions.httpsCallable("validateUsername");
      const result = await validateUsername({
        username: form.username,
      });
      if (!result.data.available) {
        newError.username = "Username tidak tersedia";
      }
    }

    if (!form.no_hp) {
      newError.no_hp = "No HP wajib diisi";
    }

    if (!form.email) {
      newError.email = "Email wajib diisi";
    } else if (!isEmail(form.email)) {
      newError.email = "Email tidak valid";
    }

    if (!form.password) {
      newError.password = "Kata sandi wajib diisi";
    }

    if (!form.repeat_password) {
      newError.repeat_password = "Ulangi kata sandi wajib diisi";
    } else if (form.repeat_password !== form.password) {
      newError.repeat_password = "Ulangi kata sandi tidak sama";
    }

    if (!form.kampus.ptn_id) {
      newError.kampus = "Kampus wajib diisi";
    } else {
      newError.kampus = ""; 
    }

    if (!form.jurusan.jurusan_id) {
      newError.jurusan = "Jurusan wajib diisi";
    } else {
      newError.jurusan = "";
    }

    return newError;
  };

  // Condition if Button Submitted
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingBd(true);
    const findErrors = await validate();
    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmitting(true);
        const user = await auth.createUserWithEmailAndPassword(
          form.email,
          form.password
        );
        const date = new Date();
        const utm_source = await getData("utm_source");
        await firestore.doc(`siswa/${user.user.uid}`).set({
          nama: form.name,
          username: form.username,
          email: form.email,
          password: PasswordHash.generate(form.password),
          check: "setuju",
          dibuat_pada: date,
          utm_source: utm_source ?? "",
          no_hp: form.no_hp,
          instagram: form.instagram,
          kampus: form.kampus,
          jurusan: form.jurusan,
        });
        await firestore.doc(`notifikasi/${user.user.uid}`).set(
          {
            inApp: true,
            ringApp: {
              id: 1,
              ring: "https://cdn.glitch.com/922ee5f5-2d45-4f6a-955c-10b16e7aee54%2Fringtone.mp3?v=1575722255364",
              nama: "Sony",
              icon: true,
            },
            ringmessage: {
              id: 3,
              ring: "https://cdn.glitch.com/fa950c3d-2965-41dd-b803-2aca6d06ce3c%2Fhallo.mp3?v=1578782795030",
              nama: "Halo",
              icon: true,
            },
          },
          { merge: true }
        );
      } catch (e) {
        const newError = {};

        switch (e.code) {
          case "auth/email-already-in-use":
            newError.email = "Email sudah terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Email tidak valid";
            break;
          case "auth/weak-password":
            newError.password = "Password lemah";
            break;
          case "auth/operation-not-allowed":
            newError.email = "Metode email dan password tidak didukung";
            break;
          default:
            newError.email = e.message;
            break;
        }
        setError(newError);
        setSubmitting(false);
      }
    }
    setLoadingBd(false);
  };

  // Check Label
  const [state, setState] = React.useState({
    check: false,
  });

  // Condition If label not checked user can't Submit the data if checked user can submit the data
  const handleChanges = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    if (state.check === false) {
      setIsSubmitting(false);
    } else if (state.check === true) {
      setIsSubmitting(true);
    }
  };

  // Label Check
  const { check } = state;

  // Condition Loading
  if (loading) {
    return <AppLoading />;
  }

  // conditions when there are users for SignUp with form
  if (user) {
    return <Redirect to="/lengkapiData" />;
  }

  return (
    <div className={classes.root}>
      <AppHeader />
      <div className={classes.pageHeading}>
        <h2 className={classes.pageTitle}>Daftar</h2>
        <p className={classes.pageSubtitle}>Isi data dengan benar</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classes.formRow}>
          <CustomTextField
            variant="outlined"
            size="small"
            id="name"
            name="name"
            placeholder="Nama Lengkap"
            className={classes.textField}
            value={form.name}
            onChange={handleChange}
            disabled={submitting || loadingBd}
          />
          {error?.name && <p className={classes.error}>{error?.name}</p>}
        </div>
        <div className={classes.formRow}>
          <CustomTextField
            variant="outlined"
            size="small"
            id="username"
            name="username"
            placeholder="Username"
            className={classes.textField}
            value={form.username}
            onChange={handleChange}
            disabled={submitting || loadingBd}
          />
          {error?.username && (
            <p className={classes.error}>{error?.username}</p>
          )}
        </div>
        <div className={classes.formRow}>
          <CustomTextField
            variant="outlined"
            size="small"
            id="password"
            name="password"
            placeholder="Kata Sandi"
            type={form.showPassword ? "text" : "password"}
            className={classes.textField}
            value={form.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {form.showPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled={submitting || loadingBd}
          />
          {error?.password && (
            <p className={classes.error}>{error?.password}</p>
          )}
        </div>
        <div className={classes.formRow}>
          <CustomTextField
            variant="outlined"
            size="small"
            id="repeat_password"
            name="repeat_password"
            placeholder="Ulangi Kata Sandi"
            type={form.showPasswordd ? "text" : "password"}
            className={classes.textField}
            value={form.repeat_password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordd}
                  >
                    {form.showPasswordd ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled={submitting || loadingBd}
          />
          {error?.repeat_password && (
            <p className={classes.error}>{error?.repeat_password}</p>
          )}
        </div>
        <div className={classes.formRow}>
          <CustomTextField
            variant="outlined"
            size="small"
            id="no_hp"
            name="no_hp"
            placeholder="No Hp (WA)"
            className={classes.textField}
            value={form.no_hp}
            onChange={handleChange}
            disabled={submitting || loadingBd}
          />
          {error?.no_hp && <p className={classes.error}>{error?.no_hp}</p>}
        </div>
        <div className={classes.formRow}>
          <CustomTextField
            variant="outlined"
            size="small"
            id="email"
            name="email"
            placeholder="Email"
            className={classes.textField}
            value={form.email}
            onChange={handleChange}
            disabled={submitting || loadingBd}
          />
          {error?.email && <p className={classes.error}>{error?.email}</p>}
        </div>
        <div className={classes.formRow}>
          <CustomTextField
            variant="outlined"
            size="small"
            id="instagram"
            name="instagram"
            placeholder="Instagram"
            className={classes.textField}
            value={form.instagram}
            onChange={handleChange}
            disabled={submitting || loadingBd}
          />
          {error?.instagram && (
            <p className={classes.error}>{error?.instagram}</p>
          )}
        </div>
        <SelectKampus
          className={classes.formRow}
          value={{ label: form.kampus.nama, value: form.kampus.ptn_id }}
          onChange={(newValue) => {
            let value = {
              nama: "",
              ptn_id: "",
            };
            if (newValue) {
              value = {
                nama: newValue.label,
                ptn_id: newValue.value,
              };
            }
            setForm({
              ...form,
              kampus: value,
              jurusan: {
                nama: "",
                jurusan_id: "",
              },
            });
          }}
          TextFieldProps={{
            disabled: submitting,
            helperText: error.kampus,
            error: !!error.kampus,
          }}
        />
        <SelectJurusan
          className={classes.formRow}
          value={{
            label: form.jurusan.nama,
            value: form.jurusan.jurusan_id,
          }}
          onChange={(newValue) => {
            let value = {
              nama: "",
              jurusan_id: "",
            };

            if (newValue) {
              value = {
                nama: newValue.label,
                jurusan_id: newValue.value,
              };
            }
            setForm({
              ...form,
              jurusan: value,
            });
          }}
          ptn_id={form.kampus.ptn_id}
          TextFieldProps={{
            disabled: submitting,
            helperText: error.jurusan,
            error: !!error.jurusan,
          }}
        />
        <div className={classes.formRow}>
          <span className={classes.skLabel}>
            <Checkbox
              className={classes.chxb}
              size="small"
              color="primary"
              checked={check}
              onChange={handleChanges("check")}
              value="check"
              disabled={submitting || loadingBd}
            />
            Saya telah membaca{" "}
            <Link className={classes.linkTo} to={"/syaratKetentuan"}>
              Syarat & Ketentuan
            </Link>{" "}
            dan{" "}
            <Link className={classes.linkTo} to={"/privacyPolicy"}>
              Privacy Policy
            </Link>{" "}
            aplikasi ini
          </span>
        </div>
        <div className={classes.formRowBtn}>
          <Button
            className={classes.kirimBtn}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting || submitting || loadingBd}
          >
            Daftar
          </Button>
          <Backdrop open={loadingBd} style={{ zIndex: 10 }}>
            <CircularProgress size={50} className={classes.buttonProgress} />
          </Backdrop>
        </div>
        <div className={classes.formRowBtn}>
          <p style={{ fontSize: 13.33 }}>
            Sudah punya akun?{" "}
            <Button
              color="primary"
              className={classes.btnRegister}
              onClick={() => props.history.push("/login")}
            >
              Masuk
            </Button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default RegisterForm;
